import { Paragraph, Wrapper, Title } from "./styled";

const Regulamin = () => (
  <Wrapper>
    <Title>Regulamin korzystania ze strony internetowej Serce Finansów</Title>
    <Paragraph>
      <ol>
        <li>
          Właścicielem i administratorem strony internetowej Serce Finansów
          dostępnej pod adresem [www.sercefinansow.pl] jest Paweł Kowalewski,
          prowadzący działalność gospodarczą pod adresem: ul. Staszica 58,
          16-400 Suwałki, NIP: 8461665032, tel. +48 783 737 050, e-mail:
          biuro@sercefinansow.pl.
        </li>
        <li>
          Korzystanie ze strony oznacza akceptację niniejszego regulaminu.
          Użytkownik, który nie akceptuje regulaminu, powinien zaprzestać
          korzystania ze strony.
        </li>
        <li>
          Strona internetowa ma charakter informacyjny i służy zachęceniu
          użytkowników do kontaktu w celu umówienia spotkania dotyczącego usług
          kredytowych. Informacje zawarte na stronie nie stanowią oferty
          handlowej w rozumieniu przepisów Kodeksu cywilnego.
        </li>
        <li>
          Użytkownik zobowiązuje się do korzystania ze strony zgodnie z
          obowiązującym prawem, dobrymi obyczajami oraz zasadami określonymi w
          niniejszym regulaminie. Zabronione jest przesyłanie treści o
          charakterze bezprawnym, obraźliwym lub wprowadzającym w błąd.
        </li>
        <li>
          Administrator nie ponosi odpowiedzialności za decyzje podjęte przez
          użytkowników na podstawie informacji zawartych na stronie, jak również
          za przerwy w dostępności serwisu wynikające z przyczyn technicznych
          lub niezależnych od administratora.
        </li>
        <li>
          Administrator dokłada wszelkich starań, aby treści na stronie były
          aktualne i rzetelne, jednak nie gwarantuje ich kompletności ani
          dokładności. Użytkownik korzysta z informacji na stronie na własne
          ryzyko.
        </li>
        <li>
          Dane osobowe użytkowników przetwarzane są zgodnie z RODO oraz ustawą o
          ochronie danych osobowych. Szczegóły dotyczące przetwarzania danych
          znajdują się w Polityce Prywatności dostępnej na stronie.
        </li>
        <li>
          Wypełnienie formularza kontaktowego na stronie wiąże się z podaniem
          danych osobowych, których przetwarzanie odbywa się po naciśnięciu
          przycisku "Wyślij". Dane te są przetwarzane wyłącznie w celu obsługi
          zgłoszenia i kontaktu z użytkownikiem.
        </li>
        <li>
          Administrator zastrzega sobie prawo do zmiany niniejszego regulaminu w
          dowolnym momencie. Zmiany obowiązują od chwili ich opublikowania na
          stronie.
        </li>
        <li>
          W sprawach nieuregulowanych niniejszym regulaminem mają zastosowanie
          przepisy Kodeksu cywilnego oraz inne obowiązujące przepisy prawa
          polskiego.
        </li>
        <li>
          Wszelkie spory wynikłe z korzystania ze strony będą rozstrzygane przez
          właściwy sąd powszechny.
        </li>
      </ol>
    </Paragraph>
  </Wrapper>
);

export default Regulamin;
