import React, { useState } from "react";
import {
  Sekcja,
  Zdjecie,
  Tytul,
  Podtytul,
  Opis,
  Kontener,
  Oswiadczenie,
  Dane,
  Wiersz,
  Paragraf,
  Input,
  Textarea,
  Button,
  StyledLink,
  Przyciski,
} from "./styled";
import sekcja9 from "../../../images/sekcja-9-pawel.jpg";

const Formularz = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const scrollToUp = () => window.scrollTo({ top: 0 });

  const handleSubmit = (event) => {
    event.preventDefault();

    const myForm = event.target;
    const formData = new FormData(myForm);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => setIsSubmitted(true))
      .catch((error) => alert(`Wystąpił błąd: ${error}`));
  };

  return (
    <Sekcja>
      <Zdjecie src={sekcja9} alt="Justyna i Paweł przy komputerze" />
      <Opis>
        <Tytul>Bezpłatna konsultacja</Tytul>
        <Podtytul>
          Wsparcie kredytowe od etapu oceny zdolności po podpisanie umowy
          kredytowej.
        </Podtytul>
        <p>
          <strong>Zapraszamy do umówienia spotkania, </strong> na którym
          porozmawiamy o Państwa indywidualnej sytuacji finansowej. Zbadamy
          zdolność kredytową, przedstawimy oferty różnych banków oraz ustalimy
          dalsze kroki współpracy.
        </p>
        <Kontener
          name="contact"
          method="POST"
          data-netlify="true"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="form-name" value="contact" />
          <input type="hidden" name="bot-field" />
          <Dane>
            <Wiersz>
              <Paragraf>Imię i nazwisko* </Paragraf>
              <Input name="name" type="text" required autocomplete="name" />
            </Wiersz>
            <Wiersz>
              <Paragraf>Nr telefonu* </Paragraf>{" "}
              <Input
                name="phone"
                type="tel"
                required
                pattern="^[0-9]{9,}$"
                minlength="9"
                title="Numer telefonu musi zawierać co najmniej 9 cyfr"
                autocomplete="tel"
              />
            </Wiersz>
            <Wiersz>
              <Paragraf>Adres e-mail*</Paragraf>{" "}
              <Input name="email" type="email" required autocomplete="email" />
            </Wiersz>
          </Dane>

          <div>
            <Wiersz>
              <Paragraf>Wiadomość</Paragraf>
              <Textarea name="message" autoComplete="off" />
            </Wiersz>
          </div>
          <Oswiadczenie>
            Wysyłając formularz oświadczam, że zapoznałem/am się z{" "}
            <StyledLink to="/polityka-prywatności" onClick={scrollToUp}>
              Polityką Prywatności
            </StyledLink>{" "}
            i{" "}
            <StyledLink to="/regulamin" onClick={scrollToUp}>
              Regulaminem
            </StyledLink>{" "}
            i akceptuję ich zapisy.
          </Oswiadczenie>
          <Przyciski>
            <p>
              <Button type="submit">Umów spotkanie</Button>
            </p>
            {isSubmitted && (
              <p>
                Wiadomość została wysłana! <br /> Dziękujemy za kontakt.
              </p>
            )}
          </Przyciski>
        </Kontener>
      </Opis>
    </Sekcja>
  );
};

export default Formularz;
