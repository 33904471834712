import styled from "styled-components";
import { Link } from "react-router-dom";

export const Sekcja = styled.section`
  display: grid;
  grid-template-columns: 0.9fr 1.1fr;
  gap: 30px;
  padding: 0 50px 0 0;
  align-items: flex-start;

  @media (max-width: ${({ theme }) => theme.breakpoint.tabletMax}px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    gap: 20px;
    padding: 0;
    justify-items: center;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobileMax}px) {
    gap: 10px;
    text-align: center;
  }
`;

export const Zdjecie = styled.img`
  width: 100%;
  max-width: 600px;
  height: 100%;
  object-fit: cover;

  @media (max-width: ${({ theme }) => theme.breakpoint.tabletMax}px) {
    order: 2; 
`;

export const Tytul = styled.h2`
  margin-bottom: 10px;
`;

export const Podtytul = styled.p`
  font-size: 18px;
  font-weight: bold;
`;

export const Opis = styled.div`
  padding: 0 20px 20px;
  max-width: 650px;

  @media (max-width: ${({ theme }) => theme.breakpoint.tabletMax}px) {
    order: 1;
  }
`;

export const Kontener = styled.form`
  font-size: 14px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 600px;
  width: 100%;
`;

export const Oswiadczenie = styled.p`
  font-size: 14px;
  margin: 5px 0;
`;

export const Dane = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobileMax}px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const Wiersz = styled.label`
  display: flex;
  flex-direction: column;
`;

export const Paragraf = styled.p`
  margin: 5px;
`;

export const Input = styled.input`
  border-radius: 18px;
  width: 180px;
  height: 36px;
  border: 1px solid ${({ theme }) => theme.color.green};
  background: ${({ theme }) => theme.color.light};
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobileMax}px) {
    width: 100%;
  }
`;

export const Textarea = styled.textarea`
  border-radius: 18px;
  width: 100%;
  height: 72px;
  border: 1px solid ${({ theme }) => theme.color.green};
  background: ${({ theme }) => theme.color.light};
  padding: 10px;
`;

export const Button = styled.button`
  cursor: pointer;
  width: 280px;
  height: 48px;
  margin-top: 5px;
  font-size: 16px;
  border-radius: 34px;
  background-color: ${({ theme }) => theme.color.green};
  border: none;
  color: ${({ theme }) => theme.color.white};
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  font-style: italic;
  color: ${({ theme }) => theme.color.black};

  &:hover {
    text-decoration: underline;
  }
`;

export const Przyciski = styled.div`
  display: flex;
  gap: 10px;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobileMax}px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;